<template>
  <div class="main-content">
    <h2 class="text-align-center mb-2">
      {{ $t('users.notifications') }}
    </h2>

    <h3 class="text-align-center">
      {{ $t('users.destinations') }}
    </h3>

    <el-table :data="destinations"
      :empty-text="$t('users.no-destinations')"
      stripe
      :show-header="true"
      class="normal-wrap destination-table hide-table-header">
      <el-table-column width="40">
        <template #default="{row}">
          <mu-mega-icon :icon="row" />
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="{row}">
          {{ row }}
        </template>
      </el-table-column>
      <el-table-column align="right">
        <template #default="{row}">
          <el-button type="success"
            @click="$router.push(`/notifications/${row}`, () => {})">
            {{ $t('general.view-details') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div v-if="showChildNotificationsSettings && currentDestinationName"
      class="text-align-center mt-2">
      <el-radio-group v-model="receivesChildNotifications">
        <el-tooltip v-for="option in childNotificationOptions"
          :key="option.display"
          effect="dark"
          :content="option.tooltipText"
          :placement="option.tooltipPlacement">
          <el-radio :label="option.value"
            :data-testid="`${option.display}-radio`">
            {{ option.display }}
          </el-radio>
        </el-tooltip>
      </el-radio-group>
    </div>

    <el-collapse-transition>
      <div v-if="currentDestinationName"
        v-loading="saving"
        class="destination-content"
        :element-loading-text="$t('general.saving')">
        <h3 class="text-align-center">
          {{ $t('users.configure-destination-notifications', { destination: currentDestinationName }) }}
        </h3>

        <div class="flex-row-centered justify-content-space-between mt-2">
          <div>
            <h4>{{ $t('menu.events') }}</h4>
          </div>
          <div>
            <el-checkbox v-model="explain"
              name="showDetailedNotifications">
              {{ $t('users.detailed-notifications') }}
            </el-checkbox>
          </div>
        </div>

        <div v-for="(messageGroup, index) in subscriptions(currentDestinationName)"
          :key="index"
          class="an-event-type">
          <el-checkbox v-model="subscribedGroups"
            :label="messageGroup.messageGroup"
            :name="messageGroup.messageGroup" />

          <el-collapse-transition>
            <ul v-if="explain">
              <li v-for="(descr, i) in messageGroup.details"
                :key="i"
                class="font-weight-300">
                {{ descr }}
              </li>
            </ul>
          </el-collapse-transition>
        </div>

        <div class="text-align-right">
          <el-button @click="updateSubscribedGroups">
            {{ $t('general.revert') }}
          </el-button>
          <el-button type="primary"
            @click="saveChanges">
            {{ $t('general.save') }}
          </el-button>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { deepClone } from '@/helpers.js'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { isFeatureEnabled } from '@/providers/runtime.js'
export default {
  name: 'UserNotifications',

  beforeRouteEnter (_to, _from, next) {
    next(vm => {
      if (vm.isManagedContext || !isFeatureEnabled('NOTIFICATIONS_ENABLED')) {
        next(resolveServicesPage())
      }
    })
  },

  data() {
    return {
      subscribedGroups: [],
      explain: false,
      saving: false,
      childNotificationOptions: [
        {
          tooltipText: this.$t('users.partner-only-tooltip'),
          tooltipPlacement: 'left',
          value: false,
          display: this.$t('users.partner-only'),
        },
        {
          tooltipText: this.$t('users.partner-and-managed-tooltip'),
          tooltipPlacement: 'right',
          value: true,
          display: this.$t('users.partner-and-managed'),
        },
      ],
      receivesChildNotifications: null,
    }
  },

  computed: {
    ...mapGetters('ApplicationContext', ['isManagedContext']),
    ...mapGetters('Auth', ['isPartnerAccount', 'isPartnerSupported', 'isMegaportSupported']),
    ...mapState({
      notificationPreferences: state => state.UserNotifications.notificationPreferences,
    }),
    destinations() {
      return Object.keys(this.notificationPreferences.destinations || {})
    },
    currentDestinationName() {
      return this.$route.params.destinationName
    },
    hasChildNotificationsField() {
      return Object.keys(this.notificationPreferences).includes('receivesChildNotifications')
    },
    showChildNotificationsSettings() {
      return this.isPartnerAccount && this.isMegaportSupported && this.hasChildNotificationsField
    },
  },

  watch: {
    notificationPreferences() {
      this.updateSubscribedGroups()
    },
    '$route.params.destinationName'() {
      this.updateSubscribedGroups()
    },
  },

  created() {
    if (this.currentDestinationName) {
      this.updateSubscribedGroups()
    }
  },

  methods: {
    subscriptions(destination) {
      return this.notificationPreferences.destinations?.[destination] || []
    },
    updateSubscribedGroups() {
      const newValue = []
      if (this.currentDestinationName) {
        for (const group of this.subscriptions(this.currentDestinationName)) {
          if (group.subscribed) {
            newValue.push(group.messageGroup)
          }
        }
      }
      this.subscribedGroups = newValue
      if (this.hasChildNotificationsField) {
        this.receivesChildNotifications = this.notificationPreferences.receivesChildNotifications
      }
    },
    saveChanges() {
      this.saving = true
      const payload = deepClone(this.notificationPreferences)

      for (const group of payload.destinations[this.currentDestinationName]) {
        group.subscribed = this.subscribedGroups.includes(group.messageGroup)
      }

      // So we can give a suitable message to the user
      payload.destinations.destinationTitle = this.currentDestinationName
      if (this.hasChildNotificationsField) payload.receivesChildNotifications = this.receivesChildNotifications

      this.$store
        .dispatch('UserNotifications/updatePreferences', payload)
        .then(() => {
          this.$store.dispatch('UserNotifications/loadPreferences')
          this.saving = false
          this.$router.push('/notifications', () => {
            // empty function is intentional
          })
        })
        .catch(() => {
          this.saving = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.main-content {
  max-width: 900px;
  min-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.destination-table {
  max-width: 400px;
  margin: auto;
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.destination-content {
  max-width: 500px;
  margin: 2rem auto 1rem auto;
  padding: 2rem;
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}

.an-event-type {
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
}
</style>
